<template>
  <div class="default flex">
    <div>
      <img src="../assets/img/ysLogo.png" alt="">
    </div>
    <h2>
      {{ $store.state.setting.systemName }}
    </h2>
    <div class="flex navbarList">
      <div class="commList ">
        <span class="iconClass iconA"><i class="icon iconfont iconrichangguanli"></i></span>
        <p>日常管理</p>
        <span class="line"></span>
      </div>
      <div class="commList ">
        <span class="iconClass iconB"><i class="icon iconfont iconxiangmuguanli"></i></span>
        <p>项目管理</p>
        <span class="line lineB"></span>
      </div>
      <div class="commList">
        <span class="iconClass iconC"><i class="icon iconfont iconshiyanfangan"></i></span>
        <p>实验方案</p>
        <span class="line lineC"></span>
      </div>
      <div class="commList">
        <span class="iconClass iconD"><i class="icon iconfont iconlunlishenpi"></i></span>
        <p>伦理审批</p>
        <span class="line lineD"></span>
      </div>
      <div class="commList">
        <span class="iconClass iconE"><i class="icon iconfont iconcaigouguanli"></i></span>
        <p>采购管理</p>
        <span class="line lineE"></span>
      </div>
      <div class="commList">
        <span class="iconClass iconF"><i class="icon iconfont icondongwushiyan"></i></span>
        <p>动物实验</p>
        <span class="line lineF"></span>
      </div>
      <div class="commList">
        <span class="iconClass iconG"><i class="icon iconfont iconziyuanguanli"></i></span>
        <p>资源管理</p>
        <span class="line lineG"></span>
      </div>
      <div class="commList">
        <span class="iconClass iconH"><i class="icon iconfont iconxitongguanli"></i></span>
        <p>系统管理</p>
        <span class="line lineH"></span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "default",
  data() {
    return {}
  },

}
</script>

<style lang="scss" scoped>
@import "../assets/css/layout";
</style>
